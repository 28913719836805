import { Component, OnInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router, NavigationEnd, NavigationCancel, NavigationError, NavigationStart, Event } from '@angular/router';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
})
export class NavbarComponent implements OnInit {
  isHome = false;
  location: Location;
  private classes: Array<string> = [];

  addBodyClasses(path) {
    this.classes = path.replace(/\/[0-9]+/g, '').split('?')[0].split('/');
    this.classes.map((key: string) => {
      if (key.length === 0) {
        this.isHome = true;
        document.body.classList.add('home-page');
      } else {
        this.isHome = false;
        document.body.classList.remove('home-page');
      }
    });
  }
  constructor(location: Location,
    private router: Router) {

    this.location = location;
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.addBodyClasses(event.url.substring(1));
      }
      if (event instanceof NavigationStart || event instanceof NavigationEnd ||
        event instanceof NavigationCancel || event instanceof NavigationError) {

        if (location.isCurrentPathEqualTo(null) || location.isCurrentPathEqualTo('/')) {
          // this.isHome = true;
        } else {
          // this.isHome = false;
        }
      }
    });
  }

  ngOnInit() {
  }
}
