import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { gsap } from 'gsap';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {

  constructor(public authService: AuthService) { }

  ngOnInit() {
    this.animateIntro();
  }

  animateIntro() {
    gsap.from('.section', { duration: 1, y: 200, autoAlpha: 0, ease: 'back', stagger: 0.05 });
  }

}
