import { Component, OnInit } from '@angular/core';
import { RouterOutlet, Router, NavigationEnd, Event } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from "./shared/services/auth.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private classes: Array<string> = [];
  show = false;

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  removeBodyClasses() {
    this.classes.map((key: string) => {
      if (key.length > 0) {
        document.body.classList.remove(key);
      }
    });
  }

  public navigate(route: string): void {
    this.router.navigateByUrl(route);
    document.getElementsByTagName('app')[0].scrollTo(0, 0);
  }

  addBodyClasses(path) {
    this.classes = path.replace(/\/[0-9]+/g, '').split('?')[0].split('/');

    this.classes.map((key: string) => {

      if (key.length > 0) {
        document.body.classList.add(key);
      }
      if (key === 'home' || key.length === 0) {
        document.body.classList.add('projects');
      } else {
        document.body.classList.remove('projects');
      }
      if (key === 'resume') {
        // document.body.classList.add('content', 'is-medium');
      } else {
        // document.body.classList.remove('content', 'is-medium');
      }
    });
  }
  constructor(public authService: AuthService,
    public auth: AngularFireAuth,
    private router: Router) {
    router.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        this.removeBodyClasses();
        this.addBodyClasses(event.url.substring(1));
      }
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.show = true;
    }, 350);
  }
}
