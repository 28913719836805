import { DOCUMENT } from '@angular/common';

import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { Entry } from 'contentful';
import { ContentfulService } from '../shared/services/contentful.service';
import { ContentfulApiService } from '../shared/services/contentful-api.service';
import { Project } from '../shared/services/project';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppModalService } from '../shared/services/app-modal.service';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, AfterViewInit {
  projects: Array<Entry<Project>>;
  public selectedProject: any;
  public currentIndex: number;
  public element: JQuery;
  private player: any;
  private ytEvent: any;
  alreadyLoaded: boolean;
  show = false;
  obs$;

  constructor(private spinner: NgxSpinnerService,
    @Inject(DOCUMENT) private document: Document,
    private contentfulApiService: ContentfulApiService,
    private cs: ContentfulService,
    private modalService: AppModalService) { }

  ngOnInit(): void {
    this.contentfulApiService.getProjects().then((projects) => {
      (this.projects = projects.items),
        this.checkCookie();
    });
    setTimeout(() => {
      this.show = true;
    }, 50);
  }

  ngAfterViewInit(): void {
    gsap.set('.box', { y: 100, opacity: 0, scale: 0.8 });
  }

  animate(): void {
    let project_item = this.document.querySelectorAll('.project-list__item');

    gsap.set(project_item, { y: 100, scale: 0.8 });
    gsap.to('.box', { duration: 1, opacity: 1, y: 0, scale: 1, ease: 'power3' });
    ScrollTrigger.batch(project_item, {
      batchMax: 2,
      onEnter: batch => {
        gsap.to(batch, { duration: 1, opacity: 1, ease: 'power3', scale: 1, y: 0, stagger: { each: 0.15 }, overwrite: true })
      }
    });
  }

  checkCookie() {
    let c = sessionStorage.getItem('loaded');
    if (c === '' || c === null) {
      this.spinner.show();
      this.alreadyLoaded = false;
      sessionStorage.setItem('loaded', 'true');
      setTimeout(() => {
        this.animate();
        this.spinner.hide();
      }, 1500)
    } else if (c) {
      this.spinner.show();
      this.alreadyLoaded = true;
      setTimeout(() => {
        this.animate();
        this.spinner.hide();
      }, 100)
    }
  }

  onClick(project, id) {
    this.currentIndex = id;
    this.selectedProject = project;
    // this.router.navigate(['/project', id])
  }

  onStateChange(event) {
    this.ytEvent = event.data;
  }

  stopVideo(): void {
    this.player.stopVideo();
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  savePlayer(player) {
    this.player = player;
    this.player.playVideo();
  }

  playVideo() {
    this.player.playVideo();
  }

  pauseVideo() {
    this.player.pauseVideo();
    this.selectedProject = null;
  }

  showModal(): void {
    this.openModal('custom-modal-1');
  }

  exitModal(): void {
    this.closeModal('custom-modal-1');
    this.selectedProject = null;
  }

}
