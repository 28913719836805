<section class="hero is-fullheight">
  <div class="hero-body">
    <div class="container">
      <div class="columns is-multiline is-tablet">
        <div class="column is-full">
          <div class="box">
            <img src="assets/images/annie-outdoors.jpg">
            <div class="text">
              <div>
                <span class="innertext">Annie Turlay</span>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-full content is-large">
          <p>I am the Director of Integrated production, currently working at SRG in Boulder, CO. I have several years of direct experience with the production process and experience with small to big budget high pressure jobs across many platforms. I am a leader and mentor on multiple accounts, a multi-tasker, self-starter and a creative problem solver who has a passion for storytelling, creative filmmaking and production.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
