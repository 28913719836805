<div *ngIf="auth.user | async as user; else showLogin">
  <div class="at-site">
    <app-navbar></app-navbar>
    <main class="at-site__main">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
<ng-template #showLogin>
  <section class="section" [ngClass]="{'show': show}">
    <div class="container">
      <div class="columns is-tablet">
        <div class="field column is-half is-offset-one-quarter">
          <label class="label">Sign In</label>
          <div class="control">
            <input type="text" class="input" placeholder="Username" #userName required>
          </div>
          <div class="control">
            <input type="password" class="input" placeholder="Password" #userPassword required>
          </div>
          <div class="control">
            <input type="button" class="button is-outlined is-fullwidth" value="Log in" (click)="authService.SignIn(userName.value, userPassword.value)">
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>
