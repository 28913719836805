import { Injectable } from '@angular/core';
import {
  ContentfulClientApi,
  Entry,
  EntryCollection,
  createClient,
} from 'contentful';

import { environment } from '../../../environments/environment';
import { Project } from './project';
import { AxiosService } from './axios.service';

@Injectable({
  providedIn: 'root',
})
export class ContentfulApiService {
  private clientApi: ContentfulClientApi;

  constructor(private axiosService: AxiosService) {
    this.clientApi = createClient({
      space: environment.contentful_config.space,
      accessToken: environment.contentful_config.accessToken,
      adapter: axiosService.getAdapter(),
    });
  }

  // /**
  //  * Get a single blog post by its slug
  //  *
  //  * @param id The slug for the project
  //  */
  // getProject(slug: string): Promise<Entry<Project>> {
  //   return this.getProjects({
  //     'fields.slug': slug,
  //   }).then((response) => response.items[0]);
  // }

  /**
   * Get listing of all projects
   *
   * @param query Filter object
   */
  getProjects(query?: object): Promise<EntryCollection<Project>> {
    return this.clientApi.getEntries<Project>(
      Object.assign({}, query, {
        content_type: 'project',
        order: 'fields.projectOrder'
      })
    );
  }
}
