  <nav class="app-nav">
    <ul>
    <li class="app-nav__item">
      <a class="app-nav__link" [routerLink]="['/']" [ngClass]="{'nav-link--is-active': isHome}">Projects</a>
    </li>
      <li class="app-nav__item">
        <a class="app-nav__link" [routerLink]="['/about-me']" routerLinkActive="nav-link--is-active">About Me</a>
      </li>
      <li class="app-nav__item">
        <a class="app-nav__link" [routerLink]="['/resume']" routerLinkActive="nav-link--is-active">Resume</a>
      </li>
    </ul>
  </nav>
