<section class="section at-hero is-medium">
  <div class="container">
    <h1 class="title is-1 white">Annie Turlay</h1>
    <h2 class="subtitle is-4 white">Director of Integrated Production living in Boulder, CO</h2>
  </div>
</section>
<section class="section section--clients">
  <div class="container content is-medium">
    <h3 class="blue title">Clients</h3>
    <p class="blue">
      Infiniti // Ice Breakers // barkTHINS // Hershey’s // Kraft Macaroni and Cheese // JELL-O // Cracker Barrel //
      Velveeta // Belvita // American Airlines // letgo // Fruit of the Loom // Old Navy // Applebee’s // Charles Schwab
      // MetLife // Hotels.com // American Express // BK // Volkswagen // Bing
    </p>
  </div>
</section>
<section class="section section--employment">
  <div class="container content is-medium">
    <div class="level">
      <div class="level-item">
        <h3 class="title">Employment</h3>
        <a class="link link--download" target="_blank"
          href="https://annie-turlay.s3.amazonaws.com/AnnieTurlay-resume.pdf"><span
            class="icon icon--cloud-download"></span></a>
      </div>
    </div>
    <div class="box employment-item">
      <strong>SRG // Boulder, CO // 2019 - Present</strong>
      <ul class="employment-item__list">
        <li>Production department lead across all accounts</li>
      </ul>
    </div>
    <div class="box employment-item">
      <strong>CP+B // Boulder, CO // 2007 - 2019</strong>
      <ul class="employment-item__list">
        <li>Lead and mentor jrs on integrated productions for Infiniti, American Airlines, Otterbox, Hershey’s, Kraft,
          Applebee’s, Fruit of the Loom, letgo</li>
        <li>Produced award winning integrated work for Kraft, letgo, Fruit of the Loom</li>
      </ul>
    </div>
    <div class="box employment-item">
      <strong>DDB // Chicago, IL // 2005 - 2007</strong>
      <ul class="employment-item__list">
        <li>Jr. produced work for Chicago Tribune, Office Max, Budweiser Select, Bud Light and internal videos</li>
        <li>Assistant to Director of Production</li>
        <li>Creative coordinator to the Bud and Bud Light group</li>
      </ul>
    </div>
  </div>
</section>
<section class="section section--education">
  <div class="container content is-medium">
    <h3 class="white title">Education</h3>
    <strong class="white">University of Colorado Boulder</strong>
    <p class="white">BA in Communication<br />Minor in French</p>
  </div>
</section>
<section class="section section--contact">
  <div class="container content is-medium">
    <h3 class="title">Contact</h3>
    <a class="link" href="mailto:annieturlay@gmail.com">Annieturlay@gmail.com</a>
  </div>
</section>
