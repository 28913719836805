// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	contentful_config: {
		// annies space
		space: 'zgwu9rrqrdas',
		accessToken: 'i5r9-IgJx0wW21AMK8QjzcCVXPG-x-O4mKLKGRR8OnU',
		// testing space
		// space: 'clnwgk0fw9s4',
		// accessToken: 'EyS3nIOeS-6xcuH0MWd5_wlZ20BglLHFhH85Ks-xgSA',

		contentTypeIds: {
			project: 'project'
		},
		order: 'project.fields.projectOrder'
	},
	hostUrl: 'http://localhost:4200',
	firebase_config: {
		apiKey: 'AIzaSyCQwRcQRczi_-gcE6cdIn-4jb1SsvqVdds',
		authDomain: 'annie-turlay.firebaseapp.com',
		projectId: 'annie-turlay',
		storageBucket: 'annie-turlay.appspot.com',
		messagingSenderId: '417316818904',
		appId: '1:417316818904:web:107ea9d55f1c0731e8acad'
	}
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
