import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PostComponent } from './featureComponents/post/post.component';
import { ProjectComponent } from './project/project.component';
import { AboutMeComponent } from './about-me/about-me.component';
import { ResumeComponent } from './resume/resume.component';

import { AuthGuard } from './shared/guard/auth.guard';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload'
};

const routes: Routes = [
  { path: '', component: ProjectComponent, redirectTo: '', pathMatch: 'full' },
  { path: 'post/:id', component: PostComponent },
  { path: 'about-me', component: AboutMeComponent, canActivate: [AuthGuard] },
  { path: 'resume', component: ResumeComponent, canActivate: [AuthGuard] },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    urlUpdateStrategy: 'eager'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
