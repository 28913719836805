import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { gsap } from 'gsap';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent implements OnInit {

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
    this.animateIntro();
  }

  animateIntro() {
    gsap.from('.column', { duration: 1, y: 50, autoAlpha: 0, ease: 'back', stagger: 0.05 });
  }

}
