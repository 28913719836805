import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { SETTINGS as AUTH_SETTINGS } from '@angular/fire/auth';
import { NgxSpinnerModule } from "ngx-spinner";

import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxYoutubePlayerModule } from "ngx-youtube-player";
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NavbarComponent } from './core/navbar/navbar.component';
import { ProjectComponent } from './project/project.component';
import { AboutMeComponent } from './about-me/about-me.component';
import { ResumeComponent } from './resume/resume.component';
import { PostComponent } from './featureComponents/post/post.component';
import { AppModalComponent } from './shared/app-modal.component';

import { MdToHtmlPipe } from './shared/pipes/md-to-html.pipe';
import { TruncatePipe } from './shared/pipes/truncate.pipe';
const config = environment.firebase_config;

@NgModule({
  declarations: [
    AppComponent,
    ResumeComponent,
    AboutMeComponent,
    PageNotFoundComponent,
    AppModalComponent,
    NavbarComponent,
    ProjectComponent,
    MdToHtmlPipe,
    PostComponent,
    TruncatePipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    NgxSpinnerModule,
    NgxYoutubePlayerModule.forRoot(),
    AngularFireModule.initializeApp(config),
    BrowserAnimationsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: AUTH_SETTINGS, useValue: { appVerificationDisabledForTesting: true } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
