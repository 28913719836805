<section class="hero is-medium">
  <div class="hero-body">
    <div class="container">
      <div class="box">
        <img class="image" src="assets/images/annie.svg" />
        <div class="hero-title-container">
          <h1 class="hero-title title is-1">Annie Turlay</h1>
          <h2 class="hero-subtitle subtitle is-3">Director of Integrated Production</h2>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- *ngIf="obs$ | async as projects" -->
<!-- *ngFor="let p of projects; let $index = index" -->
<section class="section projects">
  <div class="container is-fullhd">
    <div class="columns is-multiline project-list">
      <article class="column project-list__item" [ngClass]="{'show': show}" *ngFor="let p of projects; let $index = index"
      (click)="onClick(p, $index)" [class.selected]="p === selectedProject" (click)="openModal('custom-modal-1')">
      <a class="link link--play"><span class="icon icon--play"></span></a>
      <div [style.background-image]="'url(' + p.fields.projectImage.fields.file.url + ')'" class="project-list__image">
        <div class="background"></div>
        <div class="info">
          <div class="title is-4">{{ p.fields.projectClient }}</div>
          <div class="subtitle is-5">{{ p.fields.projectName }}</div>
          <div class="subtitle is-6"><strong>Director:</strong> {{ p.fields.projectDirector }}</div>
        </div>
      </div>
    </article>
  </div>
</div>
</section>
<h1 *ngIf="selectedProject" class="title is-1">{{selectedProject.fields.projectYtId}}</h1>
<app-modal class="site-modal" id="custom-modal-1">
  <div class="site-modal__content">
    <div class="site-modal__body modal-body">
      <div class="site-modal__video-container">
        <youtube-player
        *ngIf="selectedProject"
        protocol="https"
        (ready)="savePlayer($event)"
        (change)="onStateChange($event)"
        [videoId]="selectedProject.fields.projectYtId"
        [width]="'100%'"
        [height]="'100%'"
        [playerVars]="{
          'cc_load_policy': 1,
          'controls': 0,
          'disablekb': 1,
          'enablejsapi': 1,
          'fs': 0,
          'rel': 0,
          'showinfo': 0,
          'color': white
        }">
      </youtube-player>


      <a class="site-modal__action action--close" (click)="exitModal()">
        <span class="icon icon--close--thin"></span>
      </a>
    </div>

  </div>
</div>
<div class="site-modal__background"></div>
</app-modal>
<ngx-spinner bdColor="hsla(104, 0%, 98%, 0.5)" size="medium" color="hsla(210, 100%, 20%, 1)" type="square-jelly-box" [fullScreen]="false"></ngx-spinner>
