import { Injectable } from '@angular/core';

import { createClient } from 'contentful';
import { from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const CONFIG = environment.contentful_config;

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {

  private cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken
  });

  constructor() { }

  getBlogPost(id) {
    const promise = this.cdaClient.getEntry(id);
    return from(promise).pipe(map(entry => entry.fields));
  }

  // getBlogPosts() {
  //   const promise = this.cdaClient.getEntries({ content_type: CONFIG.contentTypeIds.blogPost })
  //   return from(promise).pipe(map(entries => entries.items), tap(console.log))
  // }

  getProjects() {
    const promise = this.cdaClient.getEntries({
      content_type: CONFIG.contentTypeIds.project,
      order: 'fields.projectOrder'
    })
    return from(promise).pipe(map(entries => entries.items), tap(console.log))
  }

  animate(): void {
    gsap.set('.project-list__item', { y: 100, scale: 0.8 });
    ScrollTrigger.batch('.project-list__item', {
      batchMax: 2,
      onEnter: batch => {
        gsap.to(batch, { duration: 1, opacity: 1, ease: 'power3', scale: 1, y: 0, stagger: { each: 0.15 }, overwrite: true })
      }
    });
  }
}
